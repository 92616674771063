import { Injectable } from "@angular/core";
import { UnitCategory } from "../model/unitcategory.model";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NotifyAlert } from "../shared/notify/notify.alert";

@Injectable({
  providedIn: "root"
})
export class UnitcategoryService {
  constructor(private http: HttpClient, private router: Router, private alert: NotifyAlert) {}

  getAllCategories() {
    return this.http.get<UnitCategory[]>("/api/unitcategory/GetAll");
  }

  // getCategoryId(id){
  //   return this.http.get<UnitCategory[]>("/api/unitcategory/");
  // }

  getList() {
    return this.http.get<UnitCategory[]>("/api/unitcategory/GetList");
  }

  getAllCategoriesWithUnits() {
    return this.http.get<UnitCategory[]>("/api/unitcategory/GetWithUnits");
  }

  createCategory(model) {
    if (model.Id == 0) {
      return this.http
        .post("/api/unitcategory", model, {
          responseType: "json"
        })
        .subscribe(
          data => {
            this.alert.success("Unit Category create with Success", "");
            if (data) {
              return data;
            }
          },
          err => this.alert.error("Error to create Unit Category", "")
        );
    } else {
      return this.http
        .put("/api/unitcategory", model, {
          responseType: "json"
        })
        .subscribe(
          data => {
            this.alert.success("Unit Category edit with Success", "");
            if (data) {
              return data;
            }
          },
          err => this.alert.error("Error to edit Unit Category", "")
        );
    }
  }

  deleteCategory(id){
    this.http.delete('/api/unitcategory/delete/'+id).subscribe(res => {
      if (res) {
        this.alert.success('Category deleted successfully','')
      } else {
        this.alert.error('Category cannot be deleted because it has units associated to an existing Deal',"")
      }
      this.router.navigateByUrl("/category");
    })
  }
}
