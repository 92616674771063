import { Stock } from "./stock.model";
import { UnitCategory } from "./unitcategory.model";

export class Unit {
  Id: number;
  Name: string;
  OnStock: number;
  InitialStock: number;
  SqMeters: number;
  //notmapped------
  SqMPrice: number;
  //---------------
  FixPrice: number=0;
  UniqueNumber: string;
  IsDependent: boolean;
  Stock: Stock;
  UnitCategory: UnitCategory;
  UnitCategoryId: number;
}
